<template>
  <div
    :class="{
      content: true,
      page: page.total > queryParams.Limit
    }"
    ref="content"
  >
    <div class="main-content">
      <div class="search-box" ref="search">
        <a-form>
          <a-row>
            <a-col :sm="5">
              <e-input
                title="角色名"
                :labelCol="6"
                :valueCol="16"
                v-model:value="queryParams.Name"
              />
            </a-col>
            <a-col :sm="8" :lg="6">
              <a-button type="primary" @click="getDataList(1)">查询</a-button>
              <a-button
                type="primary"
                @click="reset(() => resetFun())"
                danger
                style="margin-left: 10px"
              >
                重置
              </a-button>
              <a-button
                type="primary"
                style="margin-left: 10px"
                @click="addForm(() => $refs.eForm.open(null))"
                >添加角色</a-button
              >
            </a-col>
          </a-row>
        </a-form>
      </div>
      <div class="data-list">
        <a-table
          :columns="columns"
          :data-source="list"
          :pagination="false"
          :loading="loading"
          :row-key="record => record.id"
          :scroll="{ y: height, x: width }"
        >
          <template #bodyCell="{ column, record, index }">
            <template v-if="column.key === 'index'">
              {{ (page.page - 1) * queryParams.Limit + index + 1 }}
            </template>

            <template v-if="column.key === 'menus'">
              <span v-for="(v, k) in toString(record.menus)" :key="k">
                <a-tag color="orange">{{ v.name }}</a-tag>
              </span>
            </template>

            <template v-if="column.key === 'action'">
              <a-tooltip>
                <template #title>设置权限</template>
                <a @click.stop="editForm(() => $refs.eMenus.open(record))"
                  ><KeyOutlined
                /></a>
              </a-tooltip>

              <a-tooltip>
                <a-divider type="vertical" />
                <template #title>编辑</template>
                <a @click.stop="editForm(() => $refs.eForm.open(record))"
                  ><EditOutlined
                /></a>
              </a-tooltip>

              <a-tooltip v-if="record.allowDelete == 1">
                <a-divider type="vertical" />
                <template #title>删除</template>
                <a @click.stop="delForm(() => delFormFun(record))"
                  ><DeleteOutlined
                /></a>
              </a-tooltip>
            </template>
          </template>
        </a-table>
      </div>
    </div>

    <div class="pagination">
      <a-pagination
        :total="page.total"
        v-model:page-size="queryParams.Limit"
        :hide-on-single-page="true"
        :show-size-changer="true"
        :page-size-options="['20', '40', '100', '200']"
        @change="changePage"
        @showSizeChange="onShowSizeChange"
      >
        <template #itemRender="{ page, type }">
          <a v-if="type === 'prev'" class="ant-pagination-item-link">上一页</a>
          <a v-else-if="type === 'next'" class="ant-pagination-item-link">
            下一页
          </a>
          <span v-else>{{ page }}</span>
        </template>
      </a-pagination>
      <span>数据总数：{{ page.total }}</span>
    </div>

    <e-form ref="eForm" @ok="okForm" />
    <e-menus ref="eMenus" @ok="okMenus" />
  </div>
</template>

<script>
import { defineComponent, ref, createVNode } from 'vue'
import {
  EditOutlined,
  DeleteOutlined,
  KeyOutlined,
  ExclamationCircleOutlined
} from '@ant-design/icons-vue'
import { RoleClass } from '@/apis/role'
import page from '@/mixins/page'

import EForm from '@/components/forms/role/Form'
import EMenus from '@/components/forms/role/Menus'

const api = new RoleClass()
export default defineComponent({
  mixins: [page],
  components: {
    EditOutlined,
    DeleteOutlined,
    KeyOutlined,
    EForm,
    EMenus
  },
  setup () {
    const loading = ref(false)
    const height = ref(500)
    const width = ref(500)

    const columns = [
      {
        title: '序号',
        dataIndex: 'index',
        key: 'index',
        width: 60
      },
      {
        title: '角色',
        dataIndex: 'name',
        key: 'name',
        width: 120
      },
      {
        title: '权限',
        dataIndex: 'menus',
        key: 'menus'
      },
      {
        title: '操作',
        dataIndex: 'action',
        key: 'action',
        width: 100,
        fixed: 'right'
      }
    ]
    return {
      columns,
      loading,
      height,
      width
    }
  },
  created () {
    this.getDataList(1)
  },
  methods: {
    getDataList (page) {
      this.loading = true
      this.page.page = page
      const q = { ...this.queryParams }
      q.Offset = (page - 1) * q.Limit
      api.gets(q).then(resp => {
        this.list = resp.data
        this.page.total = resp.total
        this.loading = false
        this.getTableHeight()
      })
    },
    resetFun () {
      this.queryParams = {
        Limit: 20
      }

      this.getDataList(1)
    },
    delFormFun (record) {
      const _this = this
      _this.$confirm({
        title: '确定删除?',
        icon: createVNode(ExclamationCircleOutlined),
        content: createVNode(
          'div',
          {
            style: 'color:red;'
          },
          `即将删除数据：${record.name}`
        ),

        onOk () {
          api.del(record.id).then(() => {
            _this.$message.success('删除成功！')
            _this.getDataList(1)
          })
        }
      })
    },

    toString (menus) {
      if (menus.length === 0) return []
      const top = menus.filter(x => x.parentId === 0)
      return top
    },
    getTableHeight () {
      const paddingBottom = this.page.total > this.queryParams.Limit ? 50 : 9
      const mainPadding = 30
      const thead = 56
      this.height =
        this.$refs.content.offsetHeight -
        this.$refs.search.offsetHeight -
        thead -
        paddingBottom -
        mainPadding

      this.width = this.$refs.search.offsetWidth - 10
    },

    okForm (e) {
      this.getDataList(1)
    },
    okMenus () {
      this.getDataList(1)
    }
  }
})
</script>

<style lang="less" scoped>
.username {
  display: flex;
  align-items: center;
  img {
    display: block;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    overflow: hidden;
    border: 1px solid #ccc;
  }
  span {
    margin-left: 5px;
    &.sex {
      font-size: 12px;
      color: #ccc;
      &.man {
        color: rgb(66, 48, 228);
      }
      &.feman {
        color: rgb(228, 48, 48);
      }
    }
  }
}
</style>
