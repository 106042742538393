<template>
  <e-modal
    :visible="visible"
    :title="title"
    :width="500"
    @close="onClose"
    @ok="onSubmitFun"
  >
    <a-spin tip="Loading..." :spinning="loading">
      <div class="tree-body overflow-scroll">
        <a-tree
          v-if="treeData.length > 0"
          checkable
          checkStrictly
          :tree-data="treeData"
          defaultExpandAll
          v-model:checkedKeys="checkedKeys"
          :fieldNames="replaceFields"
          @check="checkTree"
        >
        <template #title="{name, remark}">
          {{name}} <a-tag v-if="remark" color="orange">{{remark}}</a-tag>
        </template>
        </a-tree>
      </div>
    </a-spin>
  </e-modal>
</template>

<script>
import { defineComponent, ref } from 'vue'
import form from '@/mixins/form'
import { RoleClass } from '@/apis/role'

const api = new RoleClass()
export default defineComponent({
  mixins: [form],
  emits: ['ok'],
  setup (props, { emit }) {
    const loading = ref(false)
    const visible = ref(false)
    const menuIds = ref([])
    const checkedKeys = ref([])
    const title = ref('')
    const role = ref({})

    const onClose = () => {
      loading.value = false
      visible.value = false
    }

    const replaceFields = {
      children: 'actions',
      title: 'name',
      key: 'id'
    }
    const treeData = []

    return {
      title,
      visible,
      loading,
      role,
      onClose,
      replaceFields,
      treeData: ref(treeData),
      menuIds,
      checkedKeys
    }
  },

  methods: {
    open (record) {
      console.log(record)
      this.title = '设置权限：' + record.name
      this.role = record
      this.checkedKeys = record.menuIdList
      this.menuIds = record.menuIdList
      this.loading = true
      this.getAllMenus()
      this.visible = true
    },
    onSubmitFun () {
      api.menus(this.role.id, { MenuIds: this.menuIds }).then(() => {
        this.$message.success('设置成功')
        this.$emit('ok')
        this.onClose()
      })
    },

    async getAllMenus () {
      const list = await this.$store.dispatch('GetMenuData', this.role.id)

      this.treeData = list
      this.loading = false
    },

    checkTree (checkedKeys) {
      this.menuIds = [...checkedKeys.checked]
    }
  }
})
</script>

<style lang="less" scoped>
.tree-body{
  height: 400px;
  overflow-x: hidden;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

  /* 滚动槽 */
  &::-webkit-scrollbar-track {
    border-radius: 10px;
  }
  /* 滚动条滑块 */
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: #1890ff;
  }
}
</style>
